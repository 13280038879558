import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
  token: null,
  reference: null,
};

const refSlice = createSlice({
  name: 'ref',
  initialState,
  reducers: {
    setTokenAndRef: (state, action) => {
      const { token, reference } = action.payload;
      state.token = token;
      state.reference = reference;
    },
  },
});

export const { setTokenAndRef } = refSlice.actions;
export default refSlice.reducer;
export const selectTokenAndRef = (state: RootState) => state.ref;
