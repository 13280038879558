import React, { Suspense } from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentToken } from '../../features/auth/authSlice'
import { useGetUserQuery } from '../../features/user/userApiSlice'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import ErrorPage from '../ErrorPage'
import pageRoutes from '../../constants/pageRoutes'
import MeetupLoader from '../../components/Loaders/MeetupLoader'

const AppLayout = () => {
  const token = useAppSelector(selectCurrentToken)
  const { data: user, error, isLoading, isSuccess, isError } = useGetUserQuery()
  const location = useLocation()

  let content
  if (isLoading) {
    content = <div className={'whirl_container whirl'} />
  } else if (isError) {
    console.log(error)
  } else if (isSuccess) {
    content = (
      <Suspense fallback={<MeetupLoader />}>
        <Outlet />
      </Suspense>
    )
  }

  return token ? (
    <ErrorBoundary fallback={<ErrorPage />}>{content}</ErrorBoundary>
  ) : (
    <Navigate to="/sign_in" state={{ from: location }} replace />
  )
}

export default AppLayout
