import React, { useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import styles from './Topbar.module.scss'
import images from '../../constants'
import { NavLink, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import pageRoutes from '../../constants/pageRoutes'
import { AiOutlineCaretDown } from 'react-icons/ai'

interface SidebarProps {
  setToggle: () => void
  showSidebar: boolean
}
const Sidebar = ({ setToggle, showSidebar }: SidebarProps) => {
  const wrapperRef = useRef(null)
  const location = useLocation()
  const { pathname } = location
  const [showFunding, setShowFunding] = useState(false)
  const [showClub, setShowClub] = useState(false)

  const handleClickOutside = (event: any) => {
    // @ts-ignore
    if ((wrapperRef.current && !wrapperRef.current.contains(event.target)) || event.target.tagName === 'a') {
      setToggle()
    }
  }

  const toggleFunding = e => {
    e.preventDefault()
    setShowFunding(!showFunding)
    setShowClub(false)
  }

  const toggleClub = e => {
    e.preventDefault()
    setShowClub(!showClub)
    setShowFunding(false)
  }

  const home = {
    name: 'Home',
    link: '/',
    image: images.homeIcon,
    class: ''
  }

  const user = {
    name: 'My Profile',
    link: pageRoutes.PROFILE,
    image: images.profile,
    class: 'first-step'
  }

  const financingApp = [
    {
      name: 'Business Profile',
      link: 'business_profile',
      image: images.business,
      class: 'second-step'
    },
    {
      name: 'Credit Profile',
      link: pageRoutes.CREDIT,
      image: images.risk,
      class: ''
    },
    /*{
      name: 'Climate Profile',
      link: '5',
      image: images.impact,
      class: ''
    },*/
    {
      name: 'Data Room',
      link: pageRoutes.DATA_ROOM,
      image: images.equity,
      class: 'third-step'
    }
  ]

  const careServices = [
    {
      name: 'Academy',
      link: 'academy',
      image: images.academy,
      class: 'fourth-step'
    },
    {
      name: 'Expert Lab',
      link: 'experts',
      image: images.experts,
      class: 'fifth-step'
    }
  ]

  const tools = [
    {
      name: 'Support',
      link: pageRoutes.SUPPORT,
      image: images.supportIcon,
      class: 'sixth-step'
    },
    {
      name: 'Business Tools',
      link: pageRoutes.MARKET,
      image: images.marketplace,
      class: ''
    }
  ]

  const handleClick = (e, link) => {
    const linkDisabled = link === '5'
    if (linkDisabled) e.preventDefault()
    setToggle()
  }

  const isFundingActive = pathname.includes('funding')
  const isClubActive = pathname.includes('konnect')
  useOnClickOutside(wrapperRef, handleClickOutside)
  return (
    <nav ref={wrapperRef} className={`${styles.nav_menu} ${showSidebar ? `${styles.nav_menu_active}` : ''}`}>
      <div>
        <img className={styles.logo} src={images.mkGreen} alt="Melanin Kapital Logo" />
        <ul>
          <img className={styles.africa_image} src={images.africa} alt="Africa Image" />
          <NavLink
            key={home.name}
            onClick={setToggle}
            to={home.link}
            className={({ isActive }) =>
              isActive ? `${styles.nav_item} ${styles.nav_item__active}` : styles.nav_item
            }>
            <img className={styles.nav_icon} src={home.image} alt={home.name} />
            <p>{home.name}</p>
          </NavLink>
          <NavLink
            key={user.name}
            to={user.link}
            onClick={setToggle}
            className={({ isActive }) =>
              isActive
                ? `${styles.nav_item} ${user.class} ${styles.nav_item__active}`
                : styles.nav_item + ' ' + user.class
            }>
            <img className={styles.nav_icon} src={user.image} alt={user.name} />
            <p>{user.name}</p>
          </NavLink>
          <h5 className={styles.head}>Financial Application</h5>
          {financingApp.map(route => (
            <NavLink
              data-tip={route.name}
              data-for={route.name}
              key={route.name}
              to={route.link}
              end={true}
              onClick={e => handleClick(e, route.link)}
              className={({ isActive }) =>
                isActive
                  ? `${styles.nav_item} ${route.class} ${styles.nav_item__active}`
                  : styles.nav_item + ' ' + route.class
              }>
              <img className={styles.nav_icon} src={route.image} alt={route.name} />
              <p>{route.name}</p>
              {route.link === '5' ? (
                <Tooltip id={route.name} className={styles.skills_tooltip}>
                  Feature to be Unlocked Soon!
                </Tooltip>
              ) : null}
            </NavLink>
          ))}
          <div className={styles.funding_drop}>
            <NavLink
              onClick={toggleFunding}
              className={`${styles.nav_item} ${isFundingActive ? styles.nav_item__active : ''}`}
              to={`${pageRoutes.FUNDING}/${pageRoutes.APPLY}/green-financing`}>
              <img className={styles.nav_icon} src={images.fundingIcon} alt={'Funding'} />
              <p>{'Funding'}</p>
              <AiOutlineCaretDown />
            </NavLink>
            <div className={`${styles.links} ${showFunding ? styles.show_links : ''}`}>
              <NavLink
                data-tip={'apply2'}
                data-for={'apply2'}
                className={({ isActive }) => (isActive ? styles.active_link : undefined)}
                to={`${pageRoutes.FUNDING}/${pageRoutes.APPLY}/green-financing`}>
                Apply For Financing
              </NavLink>
              <NavLink
                data-tip={'my-loans2'}
                data-for={'my-loans2'}
                className={({ isActive }) => (isActive ? styles.active_link : undefined)}
                to={`${pageRoutes.FUNDING}/${pageRoutes.MY_LOANS}`}>
                Asset Requests
              </NavLink>
              <Tooltip id={'apply'} className={styles.skills_tooltip}>
                Feature to be Unlocked Soon!
              </Tooltip>
              <Tooltip id={'my-loans'} className={styles.skills_tooltip}>
                Feature to be Unlocked Soon!
              </Tooltip>
            </div>
          </div>

          <h5 className={styles.head}>Care Services</h5>
          <div className={styles.funding_drop}>
            <NavLink
              data-tip={'connect'}
              data-for={'connect'}
              onClick={toggleClub}
              className={`${styles.nav_item} ${isClubActive ? styles.nav_item__active : ''}`}
              to={`${pageRoutes.KONNECT}/${pageRoutes.POSTS}`}>
              <img className={styles.nav_icon} src={images.konnect} alt={'Connect'} />
              <p>{'Club'}</p>
              <AiOutlineCaretDown />
            </NavLink>
            <div className={`${styles.links} ${showClub ? styles.show_links : ''}`}>
              <NavLink
                onClick={setToggle}
                className={({ isActive }) => (isActive ? styles.active_link : undefined)}
                to={`${pageRoutes.KONNECT}/${pageRoutes.POSTS}`}>
                Posts
              </NavLink>
              <NavLink
                onClick={setToggle}
                className={({ isActive }) => (isActive ? styles.active_link : undefined)}
                to={`${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}`}>
                Connect
              </NavLink>
              <NavLink
                onClick={setToggle}
                className={({ isActive }) => (isActive ? styles.active_link : undefined)}
                to={`${pageRoutes.KONNECT}/${pageRoutes.INBOX}`}>
                Chat
              </NavLink>
            </div>
          </div>
          {careServices.map(route => (
            <NavLink
              data-tip={route.name}
              data-for={route.name}
              onClick={e => handleClick(e, route.link)}
              key={route.name}
              to={route.link}
              className={({ isActive }) =>
                isActive
                  ? `${styles.nav_item} ${route.class} ${styles.nav_item__active}`
                  : styles.nav_item + ' ' + route.class
              }>
              <img className={styles.nav_icon} src={route.image} alt={route.name} />
              <p>{route.name}</p>
              {route.link === '5' ? (
                <Tooltip id={route.name} className={styles.skills_tooltip}>
                  Feature to be Unlocked Soon!
                </Tooltip>
              ) : null}
            </NavLink>
          ))}

          <h5 className={styles.head}>Tools</h5>
          {tools.map(route => (
            <NavLink
              data-tip={route.name}
              data-for={route.name}
              onClick={e => handleClick(e, route.link)}
              key={route.name}
              to={route.link}
              className={({ isActive }) =>
                isActive
                  ? `${styles.nav_item} ${route.class} ${styles.nav_item__active}`
                  : styles.nav_item + ' ' + route.class
              }>
              <img className={styles.nav_icon} src={route.image} alt={route.name} />
              <p>{route.name}</p>
            </NavLink>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default Sidebar
