import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TModalType } from '../../modules/ModalWindow/modalTypes'
import { RootState } from '../../app/store'

interface IModalInitialState {
  modalType: string
  document: any | null
}

const initialState: IModalInitialState = {
  modalType: '',
  document: null
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalType: TModalType
        document?: any | null
      }>
    ) => {
      const { modalType, document } = action.payload
      state.modalType = modalType
      state.document = document
    },
    closeModal: () => initialState
  }
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer

export const selectCurrentModal = (state: RootState) => state.modal.modalType
export const selectCurrentDocument = (state: RootState) => state.modal.document
