import React, { Suspense } from 'react'
import { useOutlet } from 'react-router-dom'
import MeetupLoader from './Loaders/MeetupLoader'

function WelcomeLayout() {
  const outlet = useOutlet()

  return <Suspense fallback={<MeetupLoader />}>{outlet}</Suspense>
}

export default WelcomeLayout
