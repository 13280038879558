import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  button {
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 199;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: saturate(150%) blur(0px);


    &::-webkit-scrollbar {
      width: 3px;
      height: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: #707070;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
      border-radius: 10px;
    }
  }

  .Overlay-2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 199;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: saturate(150%) blur(0px);
  }

  .paginate {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    column-gap: 0.8rem;
    align-items: center;
    list-style: none !important;

    .page {
      cursor: pointer;
      color: #636363;
      font-weight: 500;
      font-size: 1.28rem;
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 2px 5px;
      transition: .2s ease-in-out all;
    }

    .page_active {
      background-color: var(--color-primary);
      color: white;
      border-color: var(--color-primary);
    }
  }

  body #surveyElement {
    --primary: var(--color-primary) !important;
  }

  .svy-btn {

  }

  .svy-text {

  }

  .sv-string-viewer {
    font-size: 1.92rem;
  }
`;

export default GlobalStyles;
