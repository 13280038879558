import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  businessId: '',
  companyName: '',
  category_id: '',
  type_of_company: '',
  office_location: '',
  phone: '',
  email: '',
  description: '',
  linkedin: '',
  facebook: '',
  twitter: '',
  company_website: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateGenProfile: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateGenProfile } = profileSlice.actions;

export default profileSlice.reducer;
