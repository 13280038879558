import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { IMessage } from '../../types/api-responses'

interface IMessagesState {
  [userId: number]: IMessage[]
}

const initialState: IMessagesState = {}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addUserMessage: (state, action) => {
      const { userId, message } = action.payload
      if (!state[userId]) {
        state[userId] = []
      }
      state[userId].push(message)
    },
    addUserMessages: (state, action) => {
      const { userId, messages } = action.payload
      state[userId] = messages
    },
    insertUserMessages: (state, action) => {
      const { userId, messages } = action.payload
      if (!state[userId]) {
        state[userId] = []
      }
      state[userId] = [...messages, ...state[userId]]
    }
  }
})

export const { addUserMessage, addUserMessages, insertUserMessages } = messagesSlice.actions

export default messagesSlice.reducer

// Select all messages for a particular user
export const selectUserMessages: (state: RootState, userId: number) => IMessage[] = createSelector(
  (state: RootState) => state.messages,
  (_, userId: number) => userId, // @ts-ignore
  (messages: IMessage, userId) => messages[userId] || []
)
