import { apiSlice } from '../../app/api/apiSlice'
import { F_STMS, IUser, IUserWithBusiness, USER } from '../../types/api-responses'

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUser: build.query<IUser, void>({
      query: () => {
        process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
        return '/user/profile'
      },
      transformResponse: (response: any) => response?.response,
      providesTags: [{ type: USER, id: 'USER' }]
    }),
    updateProfile: build.mutation({
      query: payload => {
        const data = new FormData()
        for (const key in payload.item) {
          data.append(key, payload.item[key])
        }
        return {
          url: `user/update/${payload.id}`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: [
        { type: USER, id: 'USER' },
        { type: USER, id: 'PROGRESS' },
        { type: USER, id: 'USER_DETAILS' }
      ]
    }),
    getUserBusiness: build.query<IUserWithBusiness, number>({
      query: id => `/user/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
      providesTags: () => [{ type: USER, id: 'USER_DETAILS' }]
    }),
    createBusiness: build.mutation({
      query: (payload: any) => ({
        url: '/business/create',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [
        { type: USER, id: 'USER' },
        { type: USER, id: 'USER_DETAILS' },
        {
          type: USER,
          id: 'BUS_PROGRESS'
        }
      ]
    }),
    confirmEmail: build.mutation({
      query: ({ email }) => ({
        url: `resend/verify/email`,
        method: 'POST',
        body: { email }
      })
    }),
    sendAppointment: build.mutation({
      query: payload => ({
        url: `expert/appointment/create`,
        method: 'POST',
        body: payload
      })
    }),
    confirmPhone: build.mutation({
      query: phone => ({
        url: `/user/otp/generate`,
        method: 'POST',
        body: phone
      })
    }),
    verifyPhone: build.mutation({
      query: otp => ({
        url: `/user/otp/validate`,
        method: 'POST',
        body: otp
      }),
      invalidatesTags: [{ type: USER, id: 'USER' }]
    }),
    updateBusiness: build.mutation({
      query: payload => {
        const data = new FormData()
        for (const key in payload.item) {
          data.append(key, payload.item[key])
        }
        return {
          url: `/business/update/${payload.id}`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: [
        { type: USER, id: 'USER_DETAILS' },
        { type: USER, id: 'BUS_PROGRESS' },
        {
          type: USER,
          id: 'USER'
        }
      ]
    }),
    getBusinessDetails: build.query<any, number>({
      query: id => `/business/${id}`,
      transformResponse: (rawResult: any) => rawResult,
      providesTags: [{ type: USER, id: 'SINGLE' }]
    }),
    getBusProgress: build.query<any, number>({
      query: (id: number) => `business/profile/progress/${id}`,
      transformResponse: (rawResult: any) => rawResult.response,
      providesTags: [{ type: USER, id: 'BUS_PROGRESS' }]
    }),
    getProfileProgress: build.query<any, void>({
      query: () => `user/profile/progress`,
      transformResponse: (rawResult: any) => rawResult.response,
      providesTags: [{ type: USER, id: 'PROGRESS' }]
    }),
    deleteDocument: build.mutation({
      query: ({ isUser, fileId, modelId }) => {
        let url = ''
        if (isUser === 'user') {
          url = `user/documents/${modelId}/${fileId}`
        } else if (isUser === 'business') {
          url = `business/documents/${modelId}/${fileId}`
        } else if (isUser === 'financial') {
          url = `financial/statement/${fileId}`
        }
        return {
          url,
          method: 'DELETE'
        }
      },
      invalidatesTags: [
        { type: USER, id: 'USER_DETAILS' },
        { type: USER, id: 'BUS_PROGRESS' },
        {
          type: USER,
          id: 'USER'
        },
        { type: F_STMS, id: 'LIST' }
      ]
    })
  })
})

export const {
  useGetUserQuery,
  useUpdateProfileMutation,
  useGetUserBusinessQuery,
  useConfirmEmailMutation,
  useSendAppointmentMutation,
  useConfirmPhoneMutation,
  useVerifyPhoneMutation,
  useCreateBusinessMutation,
  useUpdateBusinessMutation,
  useGetBusinessDetailsQuery,
  useGetBusProgressQuery,
  useGetProfileProgressQuery,
  useDeleteDocumentMutation
} = userApiSlice
