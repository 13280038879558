import React from 'react'
import { useNavigate } from 'react-router-dom'
import pageRoutes from '../constants/pageRoutes'
import { Logo, Wrapper } from './ResetPassword/styles'
import images from '../constants'

function ErrorPage() {
  const navigate = useNavigate()
  const dashboardLink = `/`
  const handleClick = () => {
    navigate(dashboardLink)
  }
  return (
    <Wrapper>
      <Logo src={images.logoWhite} alt={'Melanin Kapital Logo'} />
      <div className="left" />
      <div className="container">
        <h1>Error</h1>
        <h4>
          <span>Ooops! Something went wrong</span>
        </h4>
        <p>We apologize for the inconvenience. Please return to the homepage to continue browsing.</p>
      </div>
    </Wrapper>
  )
}

export default ErrorPage
