export interface ILesson {
  id: number
  course_id: string
  video_url: string
  duration: string
  created_by: number
  status: null | string
  created_at: Date
  updated_at: Date
  title: string
  description: string
}

export interface ILoanProduct {
  id: number
  name: string
  description: string
  loan_price: string
  loan_category_id: number
  vendor_id: number
  image: string
}

export interface ILoanApplication {
  loan_product: ILoanProduct
  status: ILoanStatus
  id: number
  amount: string
  purpose: string
  business_id: number
  loan_product_id: number
  start_date: string
  status_id: number
}

export interface ILoanStatus {
  id: number
  name: string
  description: string | null
}

export interface IVendor extends IUserWithBusiness {}

export interface ICourse {
  id: number
  name: string
  description: string
  category: number
  status: string
  created_at: Date
  updated_at: Date
  poster: string
  isEnrolled?: boolean
}

export interface IConversation {
  conversation_id: number
  '0': IUser
}

export interface IMessage {
  id: number
  conversation_id: number
  user_id: number
  body: string
  created_at: Date
  updated_at: Date
}

export interface ICourseProgress {
  id: number
  participant_id: string
  lesson_id: string
  course_id: number
  start_date: string
  end_date: string | null
  revision: string | null
  duration_watched: number | null
  status: 'complete' | 'incomplete'
  created_at: string
  updated_at: string
}

export interface ILessonWithProgress extends ICourseProgress {
  lesson: ILesson
}

export interface IEnrolled {
  id: number
  course_id: number
  subscription_id: null
  start_date: string | null
  end_date: string | null
  progress: number
  status: string | null
  created_at: string
  updated_at: string
}

export interface ICourseWithProgress extends ICourse {
  progress: number
  start_date: string | null
  end_date: string | null
  status: string | null
  progress_updated_at: string | null
  progress_created_at: string | null
}

export interface IFaq {
  id: number
  question: string
  answer: string
  created_at: string
  updated_at: string
}

export interface IEvent {
  id: number
  name: string
  description: string
  date: string
  status: string | null
  poster: string
}

export interface IUser {
  id: number
  name: string
  email: string
  phone: string
  is_email_verified: number
  national_id: string | null
  gender: string | null
  date_of_birth: Date
  progress: number
  created_at: Date
  program: string | null
  media: IDocument[]
  other_info: any
  isCompany?: boolean
  phone_verified: number
  onboarding_questions_verified: number
}

export interface IBusiness {
  id: number
  name: string
  description: string
  category_id: number
  esg_score: number | null
  credit_score: number | null
  climate_score: number | null
  progress: number
  created_at: Date
  logo: string | null
  media: IDocument[]
  meta: any[]
}

export interface IUserWithBusiness extends IUser {
  companies: IBusiness[]
}

export interface StatementType {
  value: string
  label: string
}

export interface Question {
  id: string
  options: Option[]
  title: string
  question_number?: number | null
  type: string
  next_question_number?: number | null
  description: string | null
}

export interface Option {
  id: number
  text: string
  onboard_question_id: number
  key: string
  created_at: Date
  updated_at: Date
}

export interface IScores {
  scores: IScore[]
  totalScore: number
}

export interface IPost {
  id: number
  title: string
  content: string
  summary: string | null
  created_at: string
  updated_at: string
  category_id: number
  author_id: number
  likes_count: number | null
  comments_count: number | null
  forwards_count: number | null
  poster: string | null
}

export interface IComment {
  id: number
  user_id: number
  comment: string
  created_at: string
}

export interface IPostWithComments extends IPost {
  comment: IComment[]
}

export interface ICategory {
  id: number
  name: string
  description: string | null
  created_at: Date | null
  updated_at: Date | null
  parent: number | null
  type: string
}

export interface IScore {
  attributeId: number
  name: string
  score: number
}

export interface IDocument {
  id: number
  collection_name: string
  name: string
  file_name: string
  mime_type: string
  size: number
  created_at: Date
  updated_at: Date
  original_url: string
  business_id: number
}

export interface IStatement {
  id: number
  user_id: number
  business_id: number
  statement_type_id: number
  statement_name: string
  statement_url: string
  type: string
  start_date: Date
  end_date: Date
  pin: string
  national_id: number
  status: string
  created_at: Date
  updated_at: Date
  business: IBusiness
}

export interface Expert {
  imageUrl: string
  name: string
  title: string
  bio: string
  company: string
  id: number
}

export const LESSONS_TAG = 'Lessons' as const
export const COURSES_TAG = 'courses' as const
export const CATEGORIES = 'categories' as const

export const LOANS_TAG = 'loans' as const

export const BUSINESSES_TAG = 'businesses' as const

export const POSTS = 'posts' as const

export const LOAN_PRODUCTS = 'loan_products' as const

export const LOAN_TRANSACTIONS = 'loan_transactions' as const

export const TICKETS = 'tickets' as const

export const USER = 'user' as const
export const ANALYSIS = 'analysis' as const
export const ENROLL = 'enroll' as const

export const BUILT_ACC = 'built_acc' as const
export const CONNECTS = 'connects' as const
export const PENDING = 'pending' as const

export const ACCEPTED = 'accepted' as const
export const OPTIONS = 'options' as const
export const NOTIFICATIONS = 'notifications' as const
export const F_STMS = 'f_stms' as const
