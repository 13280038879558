import React, { useEffect, useRef, useState } from 'react'
import { VscMenu } from 'react-icons/vsc'
import { FiChevronDown } from 'react-icons/fi'
import { BiBell } from 'react-icons/bi'
import styles from './Topbar.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Btn, SignOut, TooltipNotification, TooltipWrapper } from './styles'
import { useAppDispatch } from '../../app/hooks'
import { AiOutlineLogout } from 'react-icons/ai'
import { BsCheck2All, BsPersonCircle } from 'react-icons/bs'
import { logOut } from '../../features/auth/authSlice'
import pageRoutes from '../../constants/pageRoutes'
import { getDocuments } from '../../utils/object-mapper'
import {
  useGetCountQuery,
  useGetNotificationsQuery,
  useMarkAllAsReadMutation
} from '../../features/notifications/notifications'
import { apiSlice } from '../../app/api/apiSlice'
import { IUser } from '../../types/api-responses'

interface TopbarProps {
  toggle: () => void
  showSidebar: boolean
  user: IUser
}

const Topbar = ({ toggle, showSidebar, user }: TopbarProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showProfile, setShowProfile] = useState(false)
  const profileRef = useRef()

  const { data: count } = useGetCountQuery()
  const { data: notifications, isSuccess, isError, error } = useGetNotificationsQuery()
  const [markAllAsRead, { isLoading: isMarking }] = useMarkAllAsReadMutation()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef()

  let notificationContent
  if (isError) {
    console.log(error)
    notificationContent = <p>Something went wrong</p>
  } else if (isSuccess) {
    if (notifications.length === 0) {
      notificationContent = <p style={{ padding: '1rem', fontSize: '1.6rem' }}>You have no new notifications</p>
    } else {
      const unreadNotifications = notifications.filter((notification: any) => notification.read_at === null).slice(0, 5)

      notificationContent = unreadNotifications.map((notification: any) => {
        return (
          <div key={notification.id} className={styles.preview}>
            <p>{notification.data.preview}</p>
          </div>
        )
      })
    }
  }

  const handleDropdownClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutsideProfile = event => {
    if (
      profileRef.current && // @ts-ignore
      !profileRef.current.contains(event.target)
    ) {
      setShowProfile(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideProfile)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideProfile)
    }
  }, [])

  const handleClickOutside = event => {
    if (
      dropdownRef.current && // @ts-ignore
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead().unwrap()
    } catch (error) {
      console.log(error)
    }
  }

  const toggleProfile = () => {
    setShowProfile(!showProfile)
  }

  const handleSignOutClick = () => {
    dispatch(apiSlice.util.resetApiState())
    dispatch(logOut())
  }

  let title: string

  switch (location.pathname) {
    case `/`:
      title = 'Entrepreneur Dashboard'
      break
    case `/${pageRoutes.PROFILE}`:
      title = 'My Profile'
      break
    case `/${pageRoutes.BUSINESS}`:
      title = 'Business Profile'
      break
    case `/${pageRoutes.CREDIT}`:
      title = 'Credit Profile'
      break
    case `/${pageRoutes.IMPACT}`:
      title = 'Impact Profile'
      break
    case `/${pageRoutes.DATA_ROOM}`:
      title = 'Data Room'
      break
    case `/${pageRoutes.FUNDING}/${pageRoutes.APPLY}`:
      title = 'Funding'
      break
    case `/${pageRoutes.FUNDING}/${pageRoutes.MY_LOANS}`:
      title = 'My Loans'
      break
    case `/${pageRoutes.KONNECT}/${pageRoutes.POSTS}`:
      title = 'Posts'
      break
    case `/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}`:
      title = 'Connect'
      break
    case `/${pageRoutes.ACADEMY}/${pageRoutes.ENROLLED}`:
    case `/${pageRoutes.ACADEMY}/${pageRoutes.CERTIFICATIONS}`:
    case `/${pageRoutes.ACADEMY}/${pageRoutes.COMPLETE}`:
    case `/${pageRoutes.ACADEMY}/${pageRoutes.INPROGRESS}`:
    case `/${pageRoutes.ACADEMY}`:
      title = 'Masterclasses'
      break
    case `/${pageRoutes.EXPERTS}`:
      title = 'Experts'
      break
    case `/${pageRoutes.SUPPORT}`:
      title = 'Support'
      break
    case `/${pageRoutes.MARKET}`:
      title = 'Business Tools'
      break
    default:
      title = 'Entrepreneur Dashboard'
  }

  const avatar = getDocuments(['avatar'], user?.media)[0]?.original_url

  const imageUrl =
    'https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg'

  return (
    <section className={styles.topbar}>
      <div className={`${styles.topbar__nav} ${showSidebar ? `${styles.active}` : ''}`}>
        <VscMenu className={styles.toggle} onClick={toggle} />
        <p className={styles.funding_header}>{title}</p>
      </div>
      <div className={styles.topbar__user}>
        <div ref={dropdownRef} onClick={handleClickOutside} data-for={'inbox-2'} className={styles.notification}>
          <BiBell onClick={handleDropdownClick} />
          {count > 0 && <span>{count}</span>}
          {isOpen && (
            <TooltipNotification
              className="dropdown-content"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.3,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01]
              }}
              key={`${isOpen}`}>
              <div className="header">
                <h3>Notifications</h3>
                <div onClick={handleMarkAllAsRead}>
                  <BsCheck2All />
                  <p>{isMarking ? <>Marking...</> : <>Mark all as read</>}</p>
                </div>
              </div>
              <hr />
              <div className="content">{notificationContent}</div>
              <Link className={'btn-link'} to={`/${pageRoutes.NOTIFICATIONS}`} onClick={() => setIsOpen(false)}>
                <Btn>See all</Btn>
              </Link>
            </TooltipNotification>
          )}
        </div>
        <div className={styles.topbar__user} ref={profileRef} onClick={handleClickOutsideProfile}>
          <TooltipWrapper
            className={`${showProfile ? 'show' : ''}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01]
            }}
            key={`${showProfile}`}>
            <SignOut
              onClick={() => {
                navigate(`/${pageRoutes.PROFILE}`)
                setShowProfile(false)
              }}
              whileTap={{ scale: 0.9 }}>
              <BsPersonCircle />
              <p>My Profile</p>
            </SignOut>
            <SignOut whileTap={{ scale: 0.9 }} onClick={handleSignOutClick}>
              <AiOutlineLogout />
              <p>Sign Out</p>
            </SignOut>
          </TooltipWrapper>
          <div className={styles.profile} onClick={toggleProfile}>
            <img
              className={styles.topbar__user__photo}
              src={`${avatar === '' || avatar === undefined ? imageUrl : avatar}`}
              alt={user.name}
              loading={'lazy'}
            />
            <div className={styles.topbar__user__profile}>
              <p>{user.name}</p>
              <p>
                Entrepreneur
                <span>
                  <FiChevronDown style={{ transform: `${showProfile ? 'scaleY(-1)' : ''}` }} />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Topbar
