import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import authReducer from '../features/auth/authSlice'
import modalReducer from '../features/modal/modalSlice'
import loanReducer from '../features/loans/loanApplicationSlice'
import profileReducer from '../features/profile/profileSlice'
import refReducer from '../features/auth/refSlice'
import messageReducer from '../features/connect/chatMessagesSlice'
import certifiedReducer from '../features/courses/certifiedSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    modal: modalReducer,
    application: loanReducer,
    profile: profileReducer,
    ref: refReducer,
    messages: messageReducer,
    certified: certifiedReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
