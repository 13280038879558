import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const certifiedSlice = createSlice({
  name: 'certified',
  initialState,
  reducers: {
    updateCertified: (state, action) => {
      return [...action.payload]
    }
  }
})

export const { updateCertified } = certifiedSlice.actions

export default certifiedSlice.reducer

export const selectCertified = state => state.certified
