import React, { lazy, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import pageRoutes from './constants/pageRoutes'
import AppLayout from './pages/AppLayout/AppLayout'
import WelcomeLayout from './components/WelcomeLayout'
import DashboardLayout from './pages/Dashboard/DashboardLayout'
import Preloader from './components/preloader/preloader'

const AddTeam = lazy(() => import('./components/BusinessProfile/team-members/add-team'))
const BusProfileSummary = lazy(() => import('./components/BusinessProfile/BusProfileSummary/BusProfileSummary'))
const CreateBusiness = lazy(() => import('./components/BusinessProfile/CreateBusiness/CreateBusiness'))
const EditBusiness = lazy(() => import('./components/BusinessProfile/EditBusiness/EditBusiness'))

const ProductDetails = lazy(() => import('./components/Funding/product-details'))
const FundingCategories = lazy(() => import('./components/Funding/FundingCategories'))
const AuthLayout = lazy(() => import('./pages/Auth/AuthLayout'))
const SignIn = lazy(() => import('./pages/Auth/SignIn/SignIn'))
const Register = lazy(() => import('./pages/Auth/Register/Register'))
const Support = lazy(() => import('./pages/Dashboard/Support/Support'))
const UserProfile = lazy(() => import('./pages/Dashboard/UserProfile/UserProfile'))
const FundingLayout = lazy(() => import('./pages/Dashboard/Funding/FundingLayout'))
const Konnect = lazy(() => import('./pages/Dashboard/Konnect/Konnect'))
const Packages = lazy(() => import('./pages/RegistrationFlow/Packages'))
const Inbox = lazy(() => import('./pages/Dashboard/Konnect/Inbox/Inbox'))
const RequestList = lazy(() => import('./components/Konnect/Request/RequestList'))
const BusinessProfileFill = lazy(() => import('./pages/RegistrationFlow/BusinessProfileFill'))
const FundingApply = lazy(() => import('./pages/Dashboard/Funding/FundingApply'))
const Post = lazy(() => import('./components/Konnect/Post/Post'))
const Splasher = lazy(() => import('./pages/Dashboard/Splasher/Splasher'))
const ClimateProfile = lazy(() => import('./pages/Dashboard/ClimateProfile/ClimateProfile'))
const EventsPage = lazy(() => import('./pages/Dashboard/Konnect/EventsPage/EventsPage'))
const NewTicket = lazy(() => import('./pages/NewTicket/NewTicket'))
const ProfilePick = lazy(() => import('./pages/RegistrationFlow/ProfilePick'))
const BusinessProfile = lazy(() => import('./pages/Dashboard/BusinessProfile/BusinessProfile'))
const RiskProfile = lazy(() => import('./pages/Dashboard/RiskProfile/RiskProfile'))
const Notifications = lazy(() => import('./components/Home/Notifications/Notifications'))
const Experts = lazy(() => import('./pages/Dashboard/Experts/Experts'))
const Marketplace = lazy(() => import('./pages/Dashboard/Marketplace/Marketplace'))
const DataRoom = lazy(() => import('./pages/Dashboard/DataRoom/DataRoom'))
const Academy = lazy(() => import('./pages/Dashboard/Academy/Academy'))
const IncompleteClasses = lazy(() => import('./components/Academy/Incomplete/IncompleteClasses'))
const Complete = lazy(() => import('./components/Academy/Complete/Complete'))
const Enrolled = lazy(() => import('./components/Academy/Enrolled/Enrolled'))
const MyCertifications = lazy(() => import('./components/Academy/Certifications/MyCertifications'))
const Course = lazy(() => import('./pages/Dashboard/Academy/Course/Course'))
const Lesson = lazy(() => import('./pages/Dashboard/Academy/Lesson/Lesson'))
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'))
const Modal = lazy(() => import('./modules/ModalWindow/Modal'))
const NewPassword = lazy(() => import('./pages/Auth/NewPassword/NewPassword'))
const ProfileSummary = lazy(() => import('./components/UserProfile/ProfileSummary/ProfileSummary'))
const EditUser = lazy(() => import('./components/EditUser/EditUser'))
const HelpfulDocs = lazy(() => import('./components/Academy/HelpfulDocs/HelpfulDocs'))
const AssetRequests = lazy(() => import('./components/Funding/MyLoans/AssetRequests'))
const AssetsFlow = lazy(() => import('./components/Funding/MyLoans/AssetsFlow'))
const PaymentChoice = lazy(() => import('./components/Funding/MyLoans/PaymentChoice'))
const MyKonnects = lazy(() => import('./components/Konnect/Request/MyKonnects/MyKonnects'))
const MyRequests = lazy(() => import('./components/Konnect/Request/MyRequests/MyRequests'))
const ExploreList = lazy(() => import('./components/Konnect/Request/ExploreList/ExploreList'))
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail/ConfirmEmail'))
const ClimateQuestion = lazy(() => import('./pages/ClimateQuiz/ClimateQuestion'))
const NotFnd = lazy(() => import('./pages/NotFnd'))
const AllList = lazy(() => import('./components/Academy/Header/AllList'))
const VerifiedEmail = lazy(() => import('./pages/VerifiedEmail'))
const ExpertPage = lazy(() => import('./pages/Dashboard/Experts/ExpertPage'))
const Chat = lazy(() => import('./components/Konnect/Inbox/Chat'))
const RecosContainer = lazy(() => import('./pages/Dashboard/Splasher/RecosContainer'))
const PostPage = lazy(() => import('./components/Konnect/Post/PostPage'))
const SplasherContainer = lazy(() => import('./pages/Dashboard/Splasher/SplasherContainer'))
const CreditQuiz = lazy(() => import('./pages/CreditQuiz/CreditQuiz'))
const VerifyPhone = lazy(() => import('./pages/ConfirmPhone/VerifyPhone'))
const ConfirmPhone = lazy(() => import('./pages/ConfirmPhone/ConfirmPhone'))
const AssetApplication = lazy(() => import('./pages/Dashboard/asset-application/asset-application'))

const App = () => {
  return (
    <div className="App" id={'app'}>
      <Modal />
      <Routes>
        <Route element={<WelcomeLayout />}>
          <Route element={<AuthLayout />}>
            <Route path={pageRoutes.SIGN_IN} element={<SignIn />} />
            <Route path={pageRoutes.SING_UP} element={<Register />} />
            <Route path={pageRoutes.NEW_PASSWORD} element={<NewPassword />} />
          </Route>
          <Route path={pageRoutes.VERIFIED_EMAIL} element={<VerifiedEmail />} />
          <Route path="*" element={<NotFnd />} />
          <Route path={pageRoutes.PRE_PROFILE} element={<ProfilePick />} />
          <Route path={pageRoutes.PACKAGES} element={<Packages />} />
          <Route path={pageRoutes.FORGOT} element={<ResetPassword />} />
          <Route path={pageRoutes.PRE_BUSINESS} element={<BusinessProfileFill />} />
          <Route path={pageRoutes.CONFIRM_EMAIL} element={<ConfirmEmail />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route path={pageRoutes.PHONE_OTP} element={<VerifyPhone />} />
          <Route path={pageRoutes.CONFIRM_PHONE} element={<ConfirmPhone />} />
          <Route path={pageRoutes.CREDIT_QUIZ} element={<CreditQuiz />} />
          <Route path={pageRoutes.CLIMATE_QUIZ} element={<ClimateQuestion />} />
          <Route element={<DashboardLayout />}>
            <Route element={<Preloader />} path={'pre-load'} />
            <Route path={pageRoutes.ASSET_APPLICATION + '/:loanProductId'} element={<AssetApplication />} />
            <Route element={<Splasher />}>
              <Route path={'/'} element={<SplasherContainer />} />
              <Route path={pageRoutes.NOTIFICATIONS} element={<Notifications />} />
            </Route>
            <Route path={pageRoutes.RECOMMENDATIONS} element={<RecosContainer />} />
            <Route path={pageRoutes.BUSINESS} element={<BusinessProfile />}>
              <Route index element={<BusProfileSummary />} />
              <Route path={pageRoutes.EDIT_BUSINESS} element={<EditBusiness />} />
              <Route path={pageRoutes.ADD_TEAM} element={<AddTeam />} />
              <Route path={pageRoutes.CREATE_BUSINESS} element={<CreateBusiness />} />
            </Route>
            <Route path={pageRoutes.CREDIT} element={<RiskProfile />} />
            <Route path={pageRoutes.FUNDING} element={<FundingLayout />}>
              <Route element={<FundingApply />}>
                <Route path={`${pageRoutes.APPLY}/:category`} element={<FundingCategories />} />
                <Route path={`${pageRoutes.APPLY}/:category/:productId`} element={<ProductDetails />} />
              </Route>
              <Route path={pageRoutes.MY_LOANS} element={<AssetsFlow />}>
                <Route index element={<PaymentChoice />} />
                <Route path={pageRoutes.PAY_CHOICE} element={<AssetRequests />} />
              </Route>
            </Route>
            <Route path={pageRoutes.KONNECT} element={<Konnect />}>
              <Route index path={pageRoutes.POSTS} element={<Post />} />
              <Route path={pageRoutes.POSTS + '/:id'} element={<PostPage />} />
              <Route path={pageRoutes.REQUESTS} element={<RequestList />}>
                <Route index element={<MyKonnects />} />
                <Route path={pageRoutes.REQUESTS_LIST} element={<MyRequests />} />
                <Route path={pageRoutes.EXPLORE} element={<ExploreList />} />
              </Route>
              <Route path={pageRoutes.INBOX} element={<Inbox />}>
                <Route path={'chat/:id'} element={<Chat />} />
              </Route>
            </Route>
            <Route path={pageRoutes.EVENTS} element={<EventsPage />} />
            <Route path={pageRoutes.ACADEMY} element={<Academy />}>
              <Route index element={<HelpfulDocs />} />
              <Route path={pageRoutes.INPROGRESS} element={<IncompleteClasses />} />
              <Route path={pageRoutes.COMPLETE} element={<Complete />} />
              <Route path={pageRoutes.ENROLLED} element={<Enrolled />} />
              <Route path={pageRoutes.CERTIFICATIONS} element={<MyCertifications />} />
            </Route>
            <Route path={`${pageRoutes.ACADEMY}/${pageRoutes.COURSE}/:courseId`} element={<Course />} />
            <Route
              path={`${pageRoutes.ACADEMY}/${pageRoutes.COURSE}/:courseId/${pageRoutes.LESSON}/:lessonId`}
              element={<Lesson />}
            />
            <Route path={`${pageRoutes.ACADEMY}/enrolled-masterclasses`} element={<AllList />} />
            <Route path={pageRoutes.SUPPORT}>
              <Route index element={<Support />} />
              <Route path={pageRoutes.NEW_TICKET} element={<NewTicket />} />
            </Route>
            <Route path={pageRoutes.EXPERTS} element={<Experts />} />
            <Route path={pageRoutes.EXPERTS + '/:id'} element={<ExpertPage />} />
            <Route path={pageRoutes.IMPACT} element={<ClimateProfile />} />
            <Route path={pageRoutes.PROFILE} element={<UserProfile />}>
              <Route index element={<ProfileSummary />} />
              <Route path={pageRoutes.USER_EDIT} element={<EditUser />} />
            </Route>
            <Route path={pageRoutes.MARKET} element={<Marketplace />} />
            <Route path={pageRoutes.DATA_ROOM} element={<DataRoom />} />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
