import React from 'react'
import styles from './preloader.module.scss'

function Preloader(props) {
  return (
    <div className={styles.container}>
      <section>
        <div className={styles.header}>
          <p />
        </div>
        <div className={styles.liner}>
          <p />
        </div>
        <div className={styles.stack}>
          <div className={styles.header}>
            <p />
          </div>
          <div className={styles.header}>
            <p />
          </div>
          <div className={styles.header}>
            <p />
          </div>
        </div>
      </section>
      <section></section>
    </div>
  )
}

export default Preloader
