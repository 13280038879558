export const TERMS_AND_CONDITIONS =
  'https://melaninkapital.sharepoint.com/:b:/s/MelaninKapitalDataroom/Eawnci9iAWtGhzxEOjZhQWMBO4wlyGikwrr_FgS57GOZgQ?e=KCaRDC'
export const REG_DOC = 'registration_document'
export const BUSS_PERMIT = 'vat_document'
export const TAX_DOC = 'tax_document'

export const MPESA_DOC = 'financial_statement'

export const PITCH_DOC = 'pitch_deck'

export const FRONT_ID = 'front_ID'
export const BACK_ID = 'back_ID'

export const EXTRA_DOC = 'extra_document'

export const OTHERS = 'others'
