import styled from 'styled-components'
import { motion } from 'framer-motion'

export const TooltipWrapper = styled(motion.div)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--shadow-light);
  width: 27rem !important;
  position: absolute;
  z-index: 3;
  top: 100%;
  right: 0.5rem;
  display: none;
  padding-bottom: 3rem;

  &.show {
    display: block;
  }
`

export const TooltipNotification = styled(motion.div)`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--shadow-light);
  position: absolute;
  z-index: 3;
  top: 150%;
  width: 35rem !important;
  right: -9rem;

  @media screen and (min-width: 768px) {
    right: 0.5rem;
    width: 40rem !important;
  }

  .header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.72rem;
      font-weight: bold;
    }

    div {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      svg {
        width: 2rem;
        height: 2rem;
        fill: #014e35;
      }

      p {
        font-size: 1.5rem;
        text-decoration: underline;
        cursor: pointer;
        color: var(--color-primary);
      }
    }
  }

  hr {
    margin: 2rem auto;
    height: 0;
    border: 0;
    border-bottom: 1px solid var(--color-grey-dark-2);
    width: 95%;
  }

  .no-notification {
    padding: 2rem;
    text-align: center;
    width: 100%;
    color: #000;
    font-size: 1.6rem;
  }
`

export const SignOut = styled(motion.div)`
  cursor: pointer;
  font-size: 1.5rem;
  padding: 1.3rem;
  margin: 0.3rem 0.5rem;
  border-radius: 5px;
  text-align: right;
  color: #000;
  display: flex;
  align-items: center;
  column-gap: 0.6rem;
  font-weight: 500;

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    background-color: var(--color-primary-light-1);
  }
`

export const Btn = styled(motion.button)`
  padding: 2rem;
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 1.6rem;
  background-color: var(--color-primary-light-1);
  margin-top: 2rem;
  border-radius: 0 0 5px 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
`
