import logoWhite from '../assets/mk_logo_white.svg'
import homeIcon from '../assets/home.svg'
import fundingIcon from '../assets/funding.svg'
import supportIcon from '../assets/suppport.svg'
import africa from '../assets/africa.svg'
import risk from '../assets/risk_profile.svg'
import business from '../assets/bus_profile.svg'
import logo from '../assets/logo.png'
import logoIcon from '../assets/mk_logo_icon.svg'
import sendIcon from '../assets/send.svg'
import konnect from '../assets/konnect.svg'
import academy from '../assets/academy.svg'
import emoji from '../assets/emoji.svg'
import googleIcon from '../assets/google.svg'
import linkedInIcon from '../assets/linkedin.svg'
import newTicket from '../assets/new_ticket.png'
import user1 from '../assets/user-1.jpg'
import user2 from '../assets/user-2.jpg'
import user3 from '../assets/user-3.jpg'
import nairobi from '../assets/bf_background.png'
import impact from '../assets/impact.svg'
import experts from '../assets/expert.svg'
import marketplace from '../assets/marketplace.svg'
import equity from '../assets/equity.svg'
import speaker from '../assets/speaker6.jpg'
import entrepreneur_profile from '../assets/entreprenuer_profile.svg'
import lender_profile from '../assets/lender_profile.svg'
import investor_profile from '../assets/investor_profile.svg'
import africaLight from '../assets/africa_light.svg'
import document from '../assets/document.png'
import mkGreen from '../assets/mk-green-logo.svg'
import document_upload from '../assets/document_upload.png'
import freeTag from '../assets/free_tag.png'
import mkBlack from '../assets/mk_logo_black.svg'
import mpesa from '../assets/mpesa_logo.svg'
import visa from '../assets/visa_logo.svg'
import mastercardLogo from '../assets/mastercard_logo.svg'
import mk_header from '../assets/mk_header.png'
import doc_reject from '../assets/doc-reject.svg'
import doc_pending from '../assets/doc-pending.svg'
import doc_approve from '../assets/doc-approve.svg'
import check_pen from '../assets/check_pen.svg'
import view from '../assets/view.svg'
import down_load from '../assets/down_load.svg'
import requirements from '../assets/requirements.png'
import steps from '../assets/steps2.svg'
import placeholderLogo from '../assets/logo-placeholder-image.png'
import placeholder from '../assets/placeholder.jpg'
import absaLogo from '../assets/ABSA_Group_Limited_Logo.svg'
import solarPanels from '../assets/solar-panels.png'
import sideBg from '../assets/climate.png'
import loan1 from '../assets/loan_icon_1.svg'
import loan2 from '../assets/loan_icon_2.svg'
import loan4 from '../assets/loan_icon_4.svg'
import matatu from '../assets/minibus.svg'
import irrigation from '../assets/Irrigation.svg'
import nairobiSplash from '../assets/nairobi-splash.png'
import forgotPassword from '../assets/forgot-password.png'
import checkMail from '../assets/check_mail.png'
import instagram from '../assets/Instagram_simple_icon.svg'
import topBackground from '../assets/top_background.png'
import emptyDocs from '../assets/empty_docs.png'
import fileTemplate from '../assets/file-template.png'
import errorIcon from '../assets/error.svg'
import photoIcon from '../assets/picture_icon.svg'
import emptyClasses from '../assets/empty-classes.png'
import emptyCert from '../assets/empty-cert.png'
import emptyIncomplete from '../assets/empty-incomplete.png'
import profile from '../assets/profile.svg'
import search from '../assets/search.svg'
import social from '../assets/social.svg'
import check from '../assets/check.svg'
import goal from '../assets/goal_progress_icon.svg'
import africaDark from '../assets/africaDark.svg'
import recommendations from '../assets/recommendations.svg'
import genderIcon from '../assets/gender-fluid.png'
import countryIcon from '../assets/coronavirus.png'

const images = {
  countryIcon,
  genderIcon,
  recommendations,
  africaDark,
  goal,
  check,
  social,
  search,
  profile,
  emptyIncomplete,
  emptyCert,
  emptyClasses,
  photoIcon,
  errorIcon,
  fileTemplate,
  emptyDocs,
  topBackground,
  instagram,
  checkMail,
  nairobiSplash,
  irrigation,
  loan1,
  loan2,
  loan4,
  matatu,
  absaLogo,
  solarPanels,
  sideBg,
  placeholder,
  placeholderLogo,
  steps,
  requirements,
  check_pen,
  down_load,
  view,
  doc_reject,
  doc_pending,
  doc_approve,
  mk_header,
  africaLight,
  homeIcon,
  fundingIcon,
  supportIcon,
  africa,
  risk,
  business,
  logo,
  logoIcon,
  sendIcon,
  konnect,
  academy,
  emoji,
  googleIcon,
  linkedInIcon,
  newTicket,
  user1,
  user2,
  user3,
  nairobi,
  impact,
  experts,
  equity,
  marketplace,
  speaker,
  entrepreneur_profile,
  lender_profile,
  investor_profile,
  logoWhite,
  document,
  mkGreen,
  document_upload,
  freeTag,
  mkBlack,
  mpesa,
  mastercardLogo,
  visa,
  forgotPassword
}

export default images
