enum pageRoutes {
  SIGN_IN = 'sign_in',
  SING_UP = 'sign_up',
  PACKAGES = 'packages',
  PRE_PROFILE = 'pick_profile',
  NOTIFICATIONS = 'notifications',
  CREDIT = 'credit_profile',
  BUSINESS = 'business_profile',
  FUNDING = 'funding',
  KONNECT = 'konnect',
  POSTS = 'posts',
  REQUESTS = 'requests',
  EVENTS = 'events',
  INBOX = 'inbox',
  ACADEMY = 'academy',
  COURSE = 'course',
  LESSON = 'lesson',
  SUPPORT = 'support',
  NEW_TICKET = 'new-ticket',
  PRE_BUSINESS = 'fill_business_profile',
  EXPERTS = 'experts',
  IMPACT = 'impact',
  APPLY = 'apply',
  PROFILE = 'profile',
  MY_LOANS = 'my_loans',
  MARKET = 'business_tools',
  DATA_ROOM = 'data-room',
  COMPLETE = 'complete',
  INPROGRESS = 'inprogress',
  CERTIFICATIONS = 'certifications',
  ENROLLED = 'enrolled',
  FORGOT = 'forgot-password',
  NEW_PASSWORD = 'password-new',
  USER_EDIT = 'edit-profile',
  PAY_CHOICE = 'pay-choice/:id',
  REQUESTS_LIST = 'requests_list',
  EXPLORE = 'explore',
  CONFIRM_EMAIL = 'confirm_email',
  CLIMATE_QUIZ = 'climate-quiz',
  VERIFIED_EMAIL = 'verified_email',
  RECOMMENDATIONS = 'recommendations',
  CREDIT_QUIZ = 'credit-quiz',
  CONFIRM_PHONE = 'confirm_phone',
  PHONE_OTP = 'phone_otp',
  ASSET_APPLICATION = 'asset_application',
  LOAN_PRODUCTS = 'loan_products',
  ADD_TEAM = 'add_team',
  CREATE_BUSINESS = 'create_business',
  EDIT_BUSINESS = 'edit_business'
}

export default pageRoutes
