import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState: { months: number; amount: number } = {
  amount: 0,
  months: 0,
};
const loanApplicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationLoan: (state, action: PayloadAction<{ months: number; amount: number }>) => {
      const { amount, months } = action.payload;
      state.amount = amount;
      state.months = months;
    },
  },
});

export const { setApplicationLoan } = loanApplicationSlice.actions;
export default loanApplicationSlice.reducer;
export const selectCurrentLoan = (state: RootState) => state.application;
