import { apiSlice } from '../../app/api/apiSlice'
import { NOTIFICATIONS } from '../../types/api-responses'

const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query<any, void>({
      query: () => '/unread/notifications',
      transformResponse: (rawResult: any) => {
        const result = rawResult?.response
        if (result === 'No notifications') {
          return []
        }
        return result
      },
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: NOTIFICATIONS, id })), { type: NOTIFICATIONS, id: 'LIST' }]
          : [{ type: NOTIFICATIONS, id: 'LIST' }]
    }),
    getCount: builder.query<any, void>({
      query: () => '/count/unread/notifications',
      transformResponse: (rawResult: any) => {
        return rawResult?.response
      },
      providesTags: () => [{ type: NOTIFICATIONS, id: 'COUNT' }]
    }),
    markAllAsRead: builder.mutation<void, void>({
      query: () => ({
        url: '/mark/all/notifications',
        method: 'POST'
      }),
      invalidatesTags: [
        { type: NOTIFICATIONS, id: 'LIST' },
        { type: NOTIFICATIONS, id: 'COUNT' }
      ]
    })
  })
})

export const { useGetNotificationsQuery, useGetCountQuery, useMarkAllAsReadMutation } = notificationsApiSlice
