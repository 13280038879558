import styled from 'styled-components';

export const Wrapper = styled.main`
  background-color: var(--color-accent-light);
  min-height: 100vh;
  position: relative;

  .left {
    clip-path: polygon(0 0, 65% 0, 35% 100%, 0% 100%);
    background-color: var(--color-primary);
    min-height: 100vh;
  }

  .container {
    border-radius: 10px;
    max-width: 500px;
    min-width: 300px;
    height: auto;
    display: grid;
    place-items: center;
    color: black;
    font-size: 1.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: white;
    padding: 4rem;
    text-align: center;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }

    h4 {
      font-size: 2rem;
    }

    button {
      margin-top: 2rem;
      background-color: var(--color-primary);
      color: white;
      border: none;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      font-size: 1.6rem;

      &:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 4rem;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 2;
`;
