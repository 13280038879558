import React, { Suspense, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Topbar from '../../components/Sidebar/Topbar'
import { Outlet } from 'react-router-dom'
import { useGetUserQuery } from '../../features/user/userApiSlice'
import { motion } from 'framer-motion'
import MeetupLoader from '../../components/Loaders/MeetupLoader'
import { ToastContainer } from 'react-toastify'

const DashboardLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false)
  const { data: user } = useGetUserQuery()

  return (
    <div className={'dashboard'}>
      <Sidebar setToggle={() => setShowSidebar(false)} showSidebar={showSidebar} />
      <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} className={'main-content'}>
        <Topbar user={user} toggle={() => setShowSidebar(true)} showSidebar={showSidebar} />
        <Suspense fallback={<MeetupLoader />}>
          <Outlet />
          <ToastContainer autoClose={1000} />
        </Suspense>
      </motion.div>
    </div>
  )
}

export default DashboardLayout
