import React from 'react'
import './style.css'

const MeetupLoader = () => (
  <div className={'meetup-container'}>
    <div className="meetup">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

export default MeetupLoader
